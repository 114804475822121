import { defaultDevtoolsOptions } from '@/config/constants';
import { IFilterParams } from '@/types/common.types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export interface IFilterStore extends IFilterParams {
  setCountry: (country: IFilterStore['country']) => void;
  setStatus: (status: IFilterStore['status']) => void;
  setCouponCode: (couponCode: IFilterStore['couponCode']) => void;
  setViewType: (viewType: IFilterStore['viewType']) => void;
  setDeliveryCenterId: (deliveryCenterId: IFilterStore['deliveryCenterId']) => void;
  setPage: (page: IFilterStore['page']) => void;
  setPageSize: (pageSize: IFilterStore['pageSize']) => void;
}

const initialState: IFilterParams = {
  country: '',
  page: 0,
  pageSize: 10,
  status: ['ACTIVE'],
  couponCode: '',
  viewType: 'grid',
  deliveryCenterId: '',
};

const persistOptions = {
  name: 'couponFilterValues',
  storage: createJSONStorage(() => localStorage),
};

const devtoolsOptions = { ...defaultDevtoolsOptions, anonymousActionType: 'useFilterStore' };

export const useFilterStore = create<IFilterStore>()(
  persist(
    devtools(
      (set) => ({
        ...initialState,
        setCountry: (country) => set({ country }),
        setPage: (page) => set({ page }),
        setPageSize: (pageSize) => set({ pageSize }),
        setStatus: (status) => set({ status }),
        setCouponCode: (couponCode) => set({ couponCode }),
        setViewType: (viewType) => set({ viewType }),
        setDeliveryCenterId: (deliveryCenterId) => set({ deliveryCenterId }),
      }),
      devtoolsOptions
    ),
    persistOptions
  )
);
