import Router from '@/Router';
import { Container } from '@/components/Grid/Grid.styles';
import { SideBar } from './AppBarConfig.styles';

const AppBarConfig = (): React.JSX.Element => {


  return (
    <SideBar>
      <Container
        style={{
          rowGap: '1rem',
          display: 'block',
          paddingTop: '24px'
        }}
        type="fluid"

      >
        <Router />
      </Container>
    </SideBar>
  );
};

export default AppBarConfig;
