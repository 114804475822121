import React, { useMemo } from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  BEARER_TOKEN: string;
  SEGMENT_KEY: string;
  supportedCountries: Array<string>;
  vendorId: { [key: string]: string };
  az_maps_account_client_id: string;
  az_maps_account_primary_key: string;
  optimizely_key: string;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  BEARER_TOKEN: '',
  SEGMENT_KEY: '',
  supportedCountries: [],
  vendorId: {},
  az_maps_account_client_id: '',
  az_maps_account_primary_key: '',
  optimizely_key: '',
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => {
  const bearerToken = localStorage.getItem('authHeader') ?? '';

  const envs: EnvConfig = useMemo(() => {
    return { ...env, BEARER_TOKEN: bearerToken };
  }, [env, bearerToken]);

  return <EnvContext.Provider value={envs}>{children}</EnvContext.Provider>;
};
