import { useHasPermission } from 'admin-portal-shared-services';
import React from 'react';

export interface Props {
  children: React.ReactNode;
  performs: Array<Array<string>>;
  fallback?: React.ReactNode;
}

const Can = ({ children, performs, fallback = null }: Props): React.JSX.Element => {
  const hasPermission = performs.map((perform) => {
    return useHasPermission(perform)
  })

  return <>{hasPermission.includes(true) ? children : fallback}</>;
};

export default Can;


