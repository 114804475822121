import { EnvConfig } from '@/components/EnvProvider';
import PERMISSION_GROUPS from '@/config/permissions';
import { useFilterStore } from '@/stores/coupon/useFilterStore';
import { useAuthenticationService, useHasPermission } from 'admin-portal-shared-services';

export const getInitialCountry = (supportedCountries: EnvConfig['supportedCountries']) => {
  const authentication = useAuthenticationService();
  const hasPermission = useHasPermission(PERMISSION_GROUPS.BEES_ADMIN);
  const storedCouponFilterValues = localStorage.getItem('couponFilterValues') ?? '{}';
  const {
    state: { country },
  } = JSON.parse(storedCouponFilterValues);

  let initialCountry = country;
  if (!initialCountry) {
    if (hasPermission) {
      initialCountry = supportedCountries[0];
    } else {
      const userSupportedCountries = authentication.getSupportedCountries();
      const filteredCountries = supportedCountries.filter((country) =>
        userSupportedCountries.includes(country)
      );
      initialCountry = filteredCountries[0];
    }
  }

  const setCountry = useFilterStore((state) => state.setCountry);
  setCountry(initialCountry);

  return initialCountry;
};
