import { IDeliveryCenterList, IDeliveryCenterProps } from '@/types/deliveryCenter.types';

export const deliveryResponseMock  = {
  content: [
    {
      active: true,
      createdDate: "2022-09-27T18:49:09.064Z",
      displayId: "TaDa Delivery DDC Honduras",
      externalId: "633345a58cd3c940e2558c41",
      id: "633345a58cd3c940e2558c41",
      name: "TaDa Delivery DDC Honduras",
      serviceModel: "1P",
      tradeName: "TaDa Delivery DDC",
      type: "DC",
      updatedDate: "2022-09-27T18:49:09.064Z",
      vendorId: "0b07df26-bd8d-45b2-985a-24518e334ab9",
      vendorLegalName: "TaDa Delivery - Honduras"
    }
  ]
}

export const deliveryCenterListMock: IDeliveryCenterList = {
  content: [
    {
      id: '62ff8f6cfa7a9c7e4a744282',
      externalId: '299',
      createdDate: '2020-10-06T14:30:35.002Z',
      updatedDate: '2021-02-10T17:00:32.360Z',
      displayId: '299',
      vendorId: 'vendorId_1407',
      name: 'Centro de Distribuição 1407',
      tradeName: 'CDD 1407',
      active: true,
      defaultLocationId: '199',
      enterpriseCode: '299',
      enterpriseFiscalNumber: '299',
      timezone: 'America/Sao_Paulo',
      serviceModel: '1P',
      answerSafetyChecklist: true,
      routeOptimizerExternalId: 'asuncion',
      coverageAreas: [
        {
          id: '60c91c8fc148e2565f29cf57',
          externalId: '2999',
          type: 'STATE',
          distributionCenterId: '62ff8f6cfa7a9c7e4a744282',
          state: 'WI',
          city: 'Menomonee Falls',
          vendorId: 'vendorId_1407',
          deliveryWindow: {
            minDays: 2,
            maxDays: 5,
          },
          createdDate: '2020-10-06T14:30:35.002Z',
          updatedDate: '2021-02-10T17:00:32.360Z',
        },
        {
          id: '60c91c7fd37cad56b40826cd',
          externalId: '2999',
          type: 'ACCOUNT_DC',
          accountsDCId: '60c91ba2a983ea88255dae60',
          accountsDCLegalName: 'Warehouse falls',
          distributionCenterId: '62ff8f6cfa7a9c7e4a744282',
          vendorId: 'vendorId_1407',
          createdDate: '2020-10-06T14:30:35.002Z',
          updatedDate: '2021-02-10T17:00:32.360Z',
        },
      ],
    },
    {
      id: '62ff8f6cfa7a9c7e4a744283',
      externalId: '299',
      createdDate: '2020-10-06T14:30:35.002Z',
      updatedDate: '2021-02-10T17:00:32.360Z',
      displayId: '299',
      vendorId: 'vendorId_1407',
      name: 'Centro de Distribuição 1407',
      tradeName: 'CDD 1407',
      active: true,
      defaultLocationId: '199',
      enterpriseCode: '299',
      enterpriseFiscalNumber: '299',
      timezone: 'America/Sao_Paulo',
      serviceModel: '1P',
      answerSafetyChecklist: true,
      routeOptimizerExternalId: 'asuncion',
      coverageAreas: [
        {
          id: '60c91c8fc148e2565f29cf57',
          externalId: '2999',
          type: 'STATE',
          distributionCenterId: '62ff8f6cfa7a9c7e4a744282',
          state: 'WI',
          city: 'Menomonee Falls',
          vendorId: 'vendorId_1407',
          deliveryWindow: {
            minDays: 2,
            maxDays: 5,
          },
          createdDate: '2020-10-06T14:30:35.002Z',
          updatedDate: '2021-02-10T17:00:32.360Z',
        },
        {
          id: '60c91c7fd37cad56b40826cd',
          externalId: '2999',
          type: 'ACCOUNT_DC',
          accountsDCId: '60c91ba2a983ea88255dae60',
          accountsDCLegalName: 'Warehouse falls',
          distributionCenterId: '62ff8f6cfa7a9c7e4a744282',
          vendorId: 'vendorId_1407',
          createdDate: '2020-10-06T14:30:35.002Z',
          updatedDate: '2021-02-10T17:00:32.360Z',
        },
      ],
    },
  ],
  pagination: {
    page: 0,
    pageSize: 10,
    totalPages: 1,
    totalElements: 1,
  },
};

export const deliveryCenterResponseMock: IDeliveryCenterProps = {
  id: '62ff8f6cfa7a9c7e4a744282',
  externalId: '299',
  createdDate: '2020-10-06T14:30:35.002Z',
  updatedDate: '2021-02-10T17:00:32.360Z',
  displayId: '299',
  vendorId: 'vendorId_1407',
  name: 'Centro de Distribuição 1407',
  tradeName: 'CDD 1407',
  active: true,
  defaultLocationId: '199',
  enterpriseCode: '299',
  enterpriseFiscalNumber: '299',
  timezone: 'America/Sao_Paulo',
  serviceModel: '1P',
  answerSafetyChecklist: true,
  routeOptimizerExternalId: 'asuncion',
  coverageAreas: [
    {
      id: '60c91c8fc148e2565f29cf57',
      externalId: '2999',
      type: 'STATE',
      distributionCenterId: '62ff8f6cfa7a9c7e4a744282',
      state: 'WI',
      city: 'Menomonee Falls',
      vendorId: 'vendorId_1407',
      deliveryWindow: {
        minDays: 2,
        maxDays: 5,
      },
      createdDate: '2020-10-06T14:30:35.002Z',
      updatedDate: '2021-02-10T17:00:32.360Z',
    },
    {
      id: '60c91c7fd37cad56b40826cd',
      externalId: '2999',
      type: 'ACCOUNT_DC',
      accountsDCId: '60c91ba2a983ea88255dae60',
      accountsDCLegalName: 'Warehouse falls',
      distributionCenterId: '62ff8f6cfa7a9c7e4a744282',
      vendorId: 'vendorId_1407',
      createdDate: '2020-10-06T14:30:35.002Z',
      updatedDate: '2021-02-10T17:00:32.360Z',
    },
  ],
};

export const emptyDeliveryCenterListMock: IDeliveryCenterList = {
  content: [],
  pagination: {
    page: 0,
    pageSize: 10,
    totalPages: 1,
    totalElements: 0,
  },
};
