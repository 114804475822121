export interface IUser {
  accountEnabled: boolean;
  contacts: Array<IUserContact>;
  customData: IUserCustomData;
  groups: Array<string>;
  status: string;
}

interface IUserContact {
  type: string;
  value: string;
  origin: string;
}

interface IUserCustomData {
  accounts: Array<string>;
  supportedCountries: Array<string>;
  vendorId?: string;
  vendors: Array<string>;
  vendorUserId?: string;
}

export interface IEnforcementUser {
  beesAccountId: string;
  email: string;
}

export type RestrictionByUserType = 'NO_USER' | 'WITH_USER' | 'BY_BRAZE';

export enum RestrictionByUserEnum {
  NO_USER = 'NO_USER',
  WITH_USER = 'WITH_USER',
  BRAZE = 'BY_BRAZE',
}
