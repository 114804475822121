export interface IPaginationProps {
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages?: number;
}

export type StatusType = 'ALL' | 'ACTIVE' | 'INACTIVE' | 'EXPIRED' | '';

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum StatusColorsEnum {
  ACTIVE = '#1F8E4A',
  INACTIVE = '#E4423F',
  EXPIRED = '#808080',
}

export enum StatusColorsBrightEnum {
  ACTIVE = '#c0f1d3',
  INACTIVE = '#f5bdbc',
  EXPIRED = '#d9d9d9',
}

export type FilterStatusType = 'ALL' | 'INACTIVE' | 'EXPIRED' | '';

export enum FilterStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface IFilterParams {
  country?: string;
  page?: number;
  pageSize?: number;
  status?: Array<StatusType>;
  couponCode?: string | string[];
  viewType?: ViewType;
  deliveryCenterId?: string;
}

export type ViewType = 'grid' | 'list';

export type AddUserType = 'email' | 'csv';
export enum AddUserTypeEnum {
  EMAIL = 'email',
  CSV = 'csv',
}

export interface IGraphqlErrors {
  errors: Array<IGraphqlError>;
}
export interface IGraphqlError {
  message: string;
  type: string;
  data: { [key: string]: any };
  status?: number;
  statusCode?: number;
}
