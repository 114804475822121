import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled(Grid.Container, {
  '&.w-full': {
    width: '100% !important',
  }
})

export const Item = styled(Grid.Item, {
  marginTop: '$2',
  
  '&.column': {
    display: 'flex',
    flexDirection: 'column',
  }
})