import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@hexa-ui/theme';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { BrowserRouter } from 'react-router-dom';
import { getApolloClient } from './Api/graphql/graphql';
import { EnvConfig, EnvProvider } from './components';
import FooterVersion from './components/FooterVersion/FooterVersion';
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from './config/featureToggles';
import useSegmentAnalytics from './hooks/useSegmentAnalytics/useSegmentAnalytics';
import { IntlProvider } from './i18n';
import AppBarConfig from './utils/AppBarConfig';
import { getInitialCountry } from './utils/AppUtils';

export default function App(props: Readonly<EnvConfig>) {
  const { optimizely_key } = props;
  createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, optimizely_key || '');

  useSegmentAnalytics(props.SEGMENT_KEY);

  const client = getApolloClient(getInitialCountry(props.supportedCountries));

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={'tadaPrimary'}>
        <EnvProvider env={props}>
          <IntlProvider>
            <BrowserRouter>
              <AppBarConfig />
              <FooterVersion />
            </BrowserRouter>
          </IntlProvider>
        </EnvProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
