const SCOPES = {
  COUPON_READ: 'AdminPortalCoupon.coupon.Read',
  COUPON_WRITE: 'AdminPortalCoupon.coupon.Write',
  ZONE_READ: 'AdminPortalCoupon.zone.Read',
  ZONE_WRITE: 'AdminPortalCoupon.zone.Write',
};

const ROLES = {
  COUPON_MANAGER: [SCOPES.COUPON_READ, SCOPES.COUPON_WRITE],
  COUPON_VIEWER: [SCOPES.COUPON_READ],
  COUNTRY_MANAGER: [SCOPES.ZONE_READ, SCOPES.ZONE_WRITE],
  COUNTRY_VIEWER: [SCOPES.ZONE_READ],
};

const PERMISSION_GROUPS = {
  BEES_ADMIN: ROLES.COUPON_MANAGER,
  BEES_VIEWER: ROLES.COUPON_VIEWER,
  COUNTRY_MANAGER: ROLES.COUNTRY_MANAGER,
  COUNTRY_VIEWER: ROLES.COUNTRY_VIEWER,
};

export default PERMISSION_GROUPS;
