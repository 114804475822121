import { rest } from 'msw';
import {
  getDeliveryCentersByIdHandler,
  getDeliveryCentersByVendorIdHandler,
} from './deliveryCenters/deliveryCenterHandlers';

export const handlers = [
  ...getDeliveryCentersByIdHandler,
  ...getDeliveryCentersByVendorIdHandler,
  rest.get('/api/v1/admin-portal-shared-bff/user-metadata', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        authorization: {
          groups: ['AdminPortalSeller.Seller Global Managers'],
          scopes: ['AdminPortalSeller.Details.Read', 'AdminPortalSeller.Details.Write'],
        },
        supportedCountries: ['HN'],
        userId: '5946772e-a00c-45cb-9e3a-0f7685318534',
      })
    );
  }),
];
