import { Error403, LoadingBuzz } from '@hexa-ui/components';
import React, { Suspense, lazy } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Can, { Props as CanProps } from './components/Can/Can';
import permissions from './config/permissions';
import BreadcrumbProvider from './providers/BreadcrumbProvider/BreadcrumbProvider';

const CouponListPage = lazy(() => import('./pages/CouponListPage/CouponListPage'));
const CouponDetailsPage = lazy(() => import('./pages/CouponDetailsPage/CouponDetailsPage'));
const NewCouponPage = lazy(() => import('./pages/NewCouponPage/NewCouponPage'));

interface RouteWithPermissionProps {
  children: React.ReactNode;
  fallback: CanProps['fallback'];
  permissions: CanProps['performs'];
}

const RouteWithPermission = ({
  children,
  permissions: hasPermissions,
  fallback,
}: RouteWithPermissionProps) => (
  <Can performs={hasPermissions} fallback={fallback}>
    {children}
  </Can>
);

export const BASENAME = '/coupon';

export const fallback = (
  <div
    style={{ height: '50vh', display: 'flex', alignItems: 'flex-end', justifyContent: ' center' }}
  >
    <LoadingBuzz />
  </div>
);

const Router = (): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <BreadcrumbProvider>
      <Suspense fallback={fallback}>
        <Can
          performs={[permissions.BEES_ADMIN, permissions.BEES_VIEWER, permissions.COUNTRY_MANAGER, permissions.COUNTRY_VIEWER]}
          fallback={
            <Error403
              header={formatMessage({ id: 'UNAUTHORIZED_PAGE.TITLE' })}
              description={formatMessage({ id: 'UNAUTHORIZED_PAGE.DESCRIPTION' })}
              buttonText={formatMessage({ id: 'UNAUTHORIZED_PAGE.ACTION' })}
            />
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={fallback}>
                  <CouponListPage />
                </Suspense>
              }
            />
            <Route
              path={`/${BASENAME}`}
              element={
                <Suspense fallback={fallback}>
                  <CouponListPage />
                </Suspense>
              }
            />
            <Route
              path={`/${BASENAME}/create/:country`}
              element={
                <RouteWithPermission
                  permissions={[permissions.BEES_ADMIN, permissions.COUNTRY_MANAGER]}
                  fallback={
                    <Error403
                      header={formatMessage({ id: 'UNAUTHORIZED_PAGE.TITLE' })}
                      description={formatMessage({ id: 'UNAUTHORIZED_PAGE.DESCRIPTION' })}
                      buttonText={formatMessage({ id: 'UNAUTHORIZED_PAGE.ACTION' })}
                      onButtonClick={() => navigate(`${BASENAME}/`)}
                    />
                  }
                >
                  <Suspense fallback={fallback}>
                    <NewCouponPage />
                  </Suspense>
                </RouteWithPermission>
              }
            />
            <Route
              path={`/${BASENAME}/:id`}
              element={
                <Suspense fallback={fallback}>
                  <CouponDetailsPage />
                </Suspense>
              }
            />
          </Routes>
        </Can>
      </Suspense>
    </BreadcrumbProvider>
  );
};
export default Router;
