import { rest } from 'msw';
import { DDC_SERVICE_BASE_URL } from '../../../config/api';
import { deliveryCenterListMock } from './deliveryCenterMocks';

export const getDeliveryCentersByIdHandler = [
  rest.get(`${DDC_SERVICE_BASE_URL}`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...deliveryCenterListMock,
      })
    );
  }),
];

export const getDeliveryCentersByVendorIdHandler = [
  rest.get(`${DDC_SERVICE_BASE_URL}`, (_req, res, ctx) => {
    const vendorId = _req.url.searchParams.get('vendorId');
    let response;

    if (vendorId === ':id') {
      response = deliveryCenterListMock;
    }

    return res(
      ctx.status(200),
      ctx.json({
        ...response,
      })
    );
  }),
];
