import { styled } from '@hexa-ui/theme';

export const FooterVersionContainer = styled('footer', {
  marginTop: '1.5rem',
  paddingLeft: '1.5rem',
  paddingBottom: '1.5rem',
  width: '100%',

  p: {
    marginBottom: '5px',
  },
});
